import LoginForm from "../components/LoginForm";
import Videos from "../components/Videos";
import React, { useState } from 'react';
import UploadForm from "../components/UploadForm";
import Animations from "../components/Animations";

function Admin() {
  const [counter, setCounter] = useState(0);
  const isLogged = localStorage.getItem('token') != null;
  const [view, setView] = useState("videos")

  return (
    <div className="main"><div className="overlay">

      {!isLogged?<LoginForm setCounter={setCounter}/> : view == "videos" ?<Videos setCounter={setCounter} setView={setView} /> :
      <Animations setCounter={setCounter} setView={setView} /> }

      {/* <UploadForm /> */}
    </div>
    </div>
  );
}

export default Admin;
