import React, { useState } from "react";
import axios from "axios";
import "./LoginForm.css";
import overlayMedia from "../assets/bmw-1-low.webp";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../environment";

function LoginForm({ setCounter }) {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const user = { login, password };
    try {
      const response = await axios.post(`https://${apiUrl}/login`, user);
      localStorage.setItem("token", response.data.token);
      setCounter((prev) => prev + 1);
      setLogin("");
      setPassword("");
    } catch (error) {
      console.error(error);
    }
  };

  const insertVideos = async (event) => {
    event.preventDefault();
    const video_link = "lol";
    const title = "lol";
    const description = "lol";
    const category = "lol";
    const animation_link = "lol";
    const video = { video_link, title, description, category, animation_link };
    try {
      const response = await axios.post(`https://${apiUrl}/videos`, video);
    } catch (error) {
      console.error(error);
    }
  };

  const styles = {
    backgroundImage: "url(" + overlayMedia + ")",
    backgroundSize: "cover",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div className="overlay" style={styles}>
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <label>
            <p>Login:</p>
            <input
              type="text"
              value={login}
              onChange={(event) => setLogin(event.target.value)}
            />
          </label>
          <label>
            <p>Password:</p>
            <input
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </label>
          <button className="login-button" type="submit">
            Login
          </button>
        </form>
        <span
          className="back-text"
          onClick={() => {
            navigate("/");
          }}
        >
          Back to homepage
        </span>
      </div>
    </div>
  );
}

export default LoginForm;
