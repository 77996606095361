import "./Content.css";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { apiUrl } from "../environment";

function Content({
  data,
  size,
  gifClasses,
  setGifClasses,
  handleAutoScroll,
  autoResizeOn,
  autoAnimationCoutner,
}) {
  const [animation, setAnimation] = useState("");
  const animationRef = useRef("");
  const [animations, setAnimations] = useState([]);
  const animamationIndex = useRef(0);

  const counter = useRef(0);
  const getAnimationsByCategory = async (category) => {
    try {
      let config = null;
      if (localStorage.getItem("token") != null) {
        config = {
          headers: {
            "x-access-token": localStorage.getItem("token").toString(),
          },
        };
      }
      const response = await axios.get(
        `https://${apiUrl}/animations/category/` + category,
        config
      );
      var item =
        response.data[Math.floor(Math.random() * response.data.length)];
      setAnimation(item.animation_link);
      animationRef.current = item.animation_link;
      setAnimations(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getAnimationTime = async (idAnimation) => {
    try {
      let config = null;
      if (localStorage.getItem("token") != null) {
        config = {
          headers: {
            "x-access-token": localStorage.getItem("token").toString(),
          },
        };
      }
      const response = await axios.get(
        `https://${apiUrl}/animations/time/` + idAnimation,
        config
      );
      return response.data.animation_time;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAnimationsByCategory("main");
    let interval = null;
    async function handleInterval() {
      var time = await getAnimationTime(1);
      if (animations.length != 0) {
        interval = setInterval(() => {
          handleOnClick();
        }, time);
      }
    }
    handleInterval();
    return () => {
      clearInterval(interval);
    };
  }, [animations.length]);

  const content = getContent();

  function getNewItem() {
    var newAnimation = animations[animamationIndex.current];
    animamationIndex.current++;
    if (animamationIndex.current == animations.length) {
      animamationIndex.current = 0;
    }
    return newAnimation;
  }

  async function handleOnClick() {
    const newAnimation = await getNewItem();
    setAnimation(newAnimation.animation_link);
    animationRef.current = newAnimation.animation_link;
    counter.current += 1;
  }

  useEffect(() => {
    if (animation != "" && autoResizeOn) {
      if (autoAnimationCoutner.current == 1) {
        handleAutoScroll();
      }
      autoAnimationCoutner.current++;
    }
  }, [animation]);

  function getContent() {
    if (data.text === "REELS" || data.text === "M.K") {
      let content = <div className="loader-black"></div>;
      if (animation != "") {
        content = (
          <img
            src={animation}
            alt="main"
            className={gifClasses}
            width={size}
            onClick={handleOnClick}
          />
        );
      }
      return content;
    }
    if (data.text === "INFO") {
      return (
        <div className="content-info">
          Experienced in short documentary form, fashion shorts, music videos
          and advertising. International experience by work in filmindustry in
          Vancouver, CA. Above all, he values the analogue, film and music
          worlds. He derives his film passion from skateboarding and traveling
          by motorbike.’ Awarded in KTR, Porsche Awards, International Motor
          Film Awards, PYD{" "}
        </div>
      );
    }
    if (
      !data.text.endsWith(":") &&
      ![
        "FASHION",
        "AUTOMOTIVE",
        "MUSIC VIDEO",
        "STORYTELLING",
        "DOCUMENTARY",
      ].includes(data.text)
    ) {
      const url =
        "https://player.vimeo.com/video/" +
        data.path.split("/")[3] +
        "?h=18985cce97&byline=0&portrait=0&title=0";
      return (
        <div className="content-video">
          <span>{}</span>
          {window.innerWidth > 650 ? (
            window.innerHeight < 450 ? (
              <iframe
                src={url}
                width="360"
                height="100"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : window.innerHeight < 450 ? (
              <iframe
                src={url}
                width="360"
                height="100"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : (
              <iframe
                src={url}
                width="640"
                height="360"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>
            )
          ) : (
            <iframe
              src={url}
              width="360"
              height="200"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
        </div>
      );
    }

    return <div></div>;
  }
  return <div className="content">{content}</div>;
}

export default Content;
