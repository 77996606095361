import { useState } from "react";
import "./MenuUpper.css";

function MenuDropdown(props) {
  const content = props.dropDownContent.map((e, i) => {
    return (
      <div
        key={i}
        className="menu-upper-link"
        onClick={() => props.handleDropdown(e.text, e.media)}
      >
        {e.text}
      </div>
    );
  });

  function handleLeave() {
    props.mouseLeave();
  }
  return (
    <div onMouseLeave={handleLeave} className={props.styles}>
      {content}
    </div>
  );
}

export default MenuDropdown;
