import { useState, useRef, useEffect } from "react";
import "./MenuUpper.css";
import MenuDropdown from "./MenuDropdown";
import axios from "axios";
import { apiUrl } from "../environment";

function MenuUpper({
  logoClicked,
  setLogoClicked,
  dropDownContent,
  setDropDownContent,
  setData,
}) {
  const [dropdownStyles, setDropdownStyles] = useState("dropdown-upper hidden");
  const lastChoice = useRef("REELS");
  const [reelsClasses, setReelsClasses] = useState("menu-upper-link");
  const [infoClasses, setInfoClasses] = useState("menu-upper-link");
  const [menuOverlayClasses, setMenuOverlayClasses] = useState("menu-overlay");
  const [menuOverlayBgClasses, setMenuOverlayBgClasses] = useState(
    "menu-overlay-bg hidden"
  );
  const [videosFashion, setVideosFashion] = useState([]);
  const [mobileVideoSelected, setMobileVideoSelected] = useState(false);
  const [mobileVideoSelected2, setMobileVideoSelected2] = useState(false);
  const [videosAutomotive, setVideosAutomotive] = useState([]);
  const [videosStorytelling, setVideosStorytelling] = useState([]);
  const [videosMusic, setVideosMusic] = useState([]);
  const [videosDocumentary, setVideosDocumentary] = useState([]);

  const getVideos = async () => {
    try {
      let config = null;
      if (localStorage.getItem("token") != null) {
        config = {
          headers: {
            "x-access-token": localStorage.getItem("token").toString(),
          },
        };
      }
      const response = await axios.get(`https://${apiUrl}/videos`, config);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    async function getVids() {
      const vids = await getVideos();
      setVideosFashion(vids.filter((v) => v.category == "fashion"));
      setVideosAutomotive(vids.filter((v) => v.category == "automotive"));
      setVideosStorytelling(vids.filter((v) => v.category == "storytelling"));
      setVideosMusic(vids.filter((v) => v.category == "music video"));
      setVideosDocumentary(vids.filter((v) => v.category == "documentary"));
    }
    getVids();
  }, []);

  useEffect(() => {
    if (logoClicked) {
      setDropdownStyles("dropdown-upper hidden");
      setReelsClasses("menu-upper-link");
      setInfoClasses("menu-upper-link");
    }
  }, [logoClicked]);

  function handleDropdown(name, path) {
    if (dropdownStyles === "dropdown-upper hidden") {
      setDropdownStyles("dropdown-upper");
    } else if (
      name === lastChoice.current ||
      (name === "REELS" &&
        ["FASHION", "AUTOMOTIVE", "MUSIC VIDEO", "STORYTELLING"].includes(
          lastChoice.current
        ))
      // ||
      // ["FASHION", "AUTOMOTIVE", "MUSIC VIDEO", "STORYTELLING"].includes(lastChoice.current) &&
      // !["FASHION", "AUTOMOTIVE", "MUSIC VIDEO", "STORYTELLING","FASHION:", "AUTOMOTIVE:", "MUSIC VIDEO:", "STORYTELLING:"].includes(name)
    ) {
      setDropdownStyles("dropdown-upper hidden");
    }
    if (name.endsWith(":")) {
      setDropDownContent([
        {
          text: "FASHION",
          media: "",
        },
        {
          text: "AUTOMOTIVE",
          media: "",
        },
        {
          text: "STORYTELLING",
          media: "",
        },
        {
          text: "MUSIC VIDEO",
          media: "",
        },
        {
          text: "DOCUMENTARY",
          media: "",
        },
      ]);
      lastChoice.current = "REELS";
    }
    if (name === "REELS") {
      if (reelsClasses === "menu-upper-link") {
        setReelsClasses("menu-upper-link selected");
        setInfoClasses("menu-upper-link");
      } else {
        setReelsClasses("menu-upper-link");
      }
      setDropDownContent([
        {
          text: "FASHION",
          media: "",
        },
        {
          text: "AUTOMOTIVE",
          media: "",
        },
        {
          text: "STORYTELLING",
          media: "",
        },
        {
          text: "MUSIC VIDEO",
          media: "",
        },
        {
          text: "DOCUMENTARY",
          media: "",
        },
      ]);
      lastChoice.current = name;
    }
    if (name === "INFO") {
      if (infoClasses === "menu-upper-link") {
        setInfoClasses("menu-upper-link selected");
        setReelsClasses("menu-upper-link");
      } else {
        name = "REELS";
        setInfoClasses("menu-upper-link");
      }
      setDropdownStyles("dropdown-upper hidden");
    }
    if (name === "FASHION") {
      var dropdownOptions = [
        {
          text: "FASHION:",
          media: "",
        },
      ];
      videosFashion.map((v) => {
        dropdownOptions.push({ text: v.title, media: v.video_link });
      });
      setDropDownContent(dropdownOptions);
      lastChoice.current = name;
    }
    if (name === "AUTOMOTIVE") {
      var dropdownOptions = [
        {
          text: "AUTOMOTIVE:",
          media: "",
        },
      ];
      videosAutomotive.map((v) => {
        dropdownOptions.push({ text: v.title, media: v.video_link });
      });
      setDropDownContent(dropdownOptions);
      lastChoice.current = name;
    }
    if (name === "STORYTELLING") {
      var dropdownOptions = [
        {
          text: "STORYTELLING:",
          media: "",
        },
      ];
      videosStorytelling.map((v) => {
        dropdownOptions.push({ text: v.title, media: v.video_link });
      });
      setDropDownContent(dropdownOptions);
      lastChoice.current = name;
    }
    if (name === "MUSIC VIDEO") {
      var dropdownOptions = [
        {
          text: "MUSIC VIDEO:",
          media: "",
        },
      ];
      videosMusic.map((v) => {
        dropdownOptions.push({ text: v.title, media: v.video_link });
      });
      setDropDownContent(dropdownOptions);
      lastChoice.current = name;
    }
    if (name === "DOCUMENTARY") {
      var dropdownOptions = [
        {
          text: "DOCUMENTARY:",
          media: "",
        },
      ];
      videosDocumentary.map((v) => {
        dropdownOptions.push({ text: v.title, media: v.video_link });
      });
      setDropDownContent(dropdownOptions);
      lastChoice.current = name;
    }
    setData({ text: name, path: path });
    setLogoClicked(false);
    handleLinkGrey(name);
    lastChoice.current = name;
    if (name == "INFO") {
      setDropDownContent([
        {
          text: "FASHION",
          media: "",
        },
        {
          text: "AUTOMOTIVE",
          media: "",
        },
        {
          text: "STORYTELLING",
          media: "",
        },
        {
          text: "MUSIC VIDEO",
          media: "",
        },
        {
          text: "DOCUMENTARY",
          media: "",
        },
      ]);
    }
    if (mobileVideoSelected2 && name == "REELS") {
      setDropdownStyles("dropdown-upper hidden");
      setReelsClasses("menu-upper-link");
      setMobileVideoSelected2(false);
    }
  }
  function handleEnter() {
    setDropdownStyles("dropdown-upper");
    setReelsClasses("menu-upper-link selected");
    setInfoClasses("menu-upper-link");
    handleLinkGrey(lastChoice.current);
  }

  function handleLeave() {
    setDropdownStyles("dropdown-upper hidden");
    if (lastChoice.current == "INFO") {
      setInfoClasses("menu-upper-link selected");
    }
    setReelsClasses("menu-upper-link");
  }

  function handleLinkGrey(name) {
    setTimeout(function () {
      var links = document.getElementsByClassName("menu-upper-link");
      for (var i = 0; i < links.length; i++) {
        if (
          !["REELS", "INFO", "CONTACT", "FOLLOW"].includes(links[i].innerText)
        ) {
          if (
            [
              "FASHION:",
              "AUTOMOTIVE:",
              "MUSIC VIDEO:",
              "STORYTELLING:",
              "DOCUMENTARY:",
            ].includes(links[i].innerText) &&
            [
              "FASHION",
              "AUTOMOTIVE",
              "MUSIC VIDEO",
              "STORYTELLING",
              "DOCUMENTARY",
            ].includes(name)
          ) {
            links[i].style.textDecoration = "underline";
            links[i].style.color = "#FFF";
          } else if (links[i].innerText == name) {
            links[i].style.textDecoration = "underline";
            links[i].style.color = "#000";
            if (window.innerWidth < 500) {
              setDropdownStyles("dropdown-upper hidden");
              setReelsClasses("menu-upper-link");
              setMobileVideoSelected(true);
            }
          } else {
            links[i].style.textDecoration = "none";
            links[i].style.color = "#707A7F";
          }
          if (
            [
              "FASHION",
              "AUTOMOTIVE",
              "MUSIC VIDEO",
              "STORYTELLING",
              "DOCUMENTARY",
              "REELS",
              "INFO",
              "CONTACT",
              "FOLLOW",
            ].includes(links[i].innerText)
          ) {
            links[i].style.color = "unset";
          }
        }
      }
    }, 0);
  }

  function handleMobileVideoSelected() {
    setTimeout(() => {
      setDropdownStyles("dropdown-upper");
      setReelsClasses("menu-upper-link selected");
      setMobileVideoSelected(false);
      setMobileVideoSelected2(true);
    }, 0);
  }

  return (
    <div className="menu-upper">
      <div
        className={reelsClasses}
        onClick={() => {
          mobileVideoSelected
            ? handleMobileVideoSelected()
            : handleDropdown("REELS");
        }}
      >
        <span onMouseEnter={handleEnter}>REELS</span>
      </div>
      <div className={infoClasses} onClick={() => handleDropdown("INFO")}>
        INFO
      </div>
      <MenuDropdown
        styles={dropdownStyles}
        mouseLeave={handleLeave}
        handleDropdown={handleDropdown}
        dropDownContent={dropDownContent}
      />
    </div>
  );
}

export default MenuUpper;
