import overlayMedia from "../assets/bmw-1-low.webp";
import { useEffect, useState } from "react";
import axios from "axios";
import "./Animations.css";
import { apiUrl } from "../environment";

function Animations({ setCounter, setView }) {
  const [Animations, setAnimations] = useState([]);
  const [editAnimationId, setEditAnimationId] = useState(null);
  const [editAnimationMode, setEditAnimationMode] = useState(false);
  const [addAnimationMode, setAddAnimationMode] = useState(false);
  const [category, setCategory] = useState("main");
  const [animation_link, setAnimationLink] = useState("");
  const [AnimationLinkError, setAnimationLinkError] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [animation_time, setAnimationTime] = useState(0);

  const handleSubmitEdit = async (event) => {
    event.preventDefault();
    const AnimationData = {
      animation_link,
      category,
    };
    const updateAnimationData = async (AnimationData) => {
      let config = null;
      if (localStorage.getItem("token") !== null) {
        config = {
          headers: {
            "x-access-token": localStorage.getItem("token").toString(),
          },
        };
      }

      try {
        const response = await axios.put(
          `https://${apiUrl}/animations/` + editAnimationId,
          AnimationData,
          config
        );
        setEditAnimationMode(false);
        setEditAnimationId(null);
      } catch (error) {}
    };
    await updateAnimationData(AnimationData);
    setAnimationLink("");
    setCategory("main");
    setIsLoader(true);
    setTimeout(getAnimations, 1000);
    setTimeout(() => {
      setIsLoader(false);
    }, 1001);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const AnimationData = {
      animation_link,
      category,
    };

    const postAnimationData = async (AnimationData) => {
      let config = null;
      if (localStorage.getItem("token") !== null) {
        config = {
          headers: {
            "x-access-token": localStorage.getItem("token").toString(),
          },
        };
      }

      try {
        const response = await axios.post(
          `https://${apiUrl}/animations`,
          AnimationData,
          config
        );

        setAddAnimationMode(false);
      } catch (error) {}
    };
    await postAnimationData(AnimationData);
    setAnimationLink("");
    setCategory("main");
    setIsLoader(true);
    setTimeout(getAnimations, 1000);
    setTimeout(() => {
      setIsLoader(false);
    }, 1001);
  };

  function back() {
    setAnimationLink("");
    setCategory("main");
    setAddAnimationMode(false);
    setEditAnimationMode(false);
    setEditAnimationId(null);
  }

  const validateLink = (link) => {
    // simple URL validation, assuming http(s) protocol
    const regex = /^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/;
    return regex.test(link);
  };

  const styles = {
    backgroundImage: "url(" + overlayMedia + ")",
    backgroundSize: "cover",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const getAnimations = async () => {
    try {
      let config = null;
      if (localStorage.getItem("token") != null) {
        config = {
          headers: {
            "x-access-token": localStorage.getItem("token").toString(),
          },
        };
      }
      const response = await axios.get(`https://${apiUrl}/animations`, config);
      setAnimations(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getAnimation = async (idAnimation) => {
    try {
      let config = null;
      if (localStorage.getItem("token") != null) {
        config = {
          headers: {
            "x-access-token": localStorage.getItem("token").toString(),
          },
        };
      }
      const response = await axios.get(
        `https://${apiUrl}/animations/` + idAnimation,
        config
      );
      setAnimationLink(response.data.animation_link);
      setCategory(response.data.category);
    } catch (error) {
      console.error(error);
    }
  };

  const getAnimationTime = async (idAnimation) => {
    try {
      let config = null;
      if (localStorage.getItem("token") != null) {
        config = {
          headers: {
            "x-access-token": localStorage.getItem("token").toString(),
          },
        };
      }
      const response = await axios.get(
        `https://${apiUrl}/animations/time/` + idAnimation,
        config
      );
      setAnimationTime(response.data.animation_time);
    } catch (error) {
      console.error(error);
    }
  };
  const deleteAnimation = async (idAnimation) => {
    try {
      let config = null;
      if (localStorage.getItem("token") != null) {
        config = {
          headers: {
            "x-access-token": localStorage.getItem("token").toString(),
          },
        };
      }
      const response = await axios.delete(
        `https://${apiUrl}/animations/` + idAnimation,
        config
      );

      setAnimations((current) =>
        current.filter((Animation) => Animation.id !== idAnimation)
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAnimations();
  }, [addAnimationMode]);

  useEffect(() => {
    getAnimationTime(1);
  }, []);

  const handleAnimationTimeUpdate = async (event) => {
    event.preventDefault();
    const updateAnimationTimeData = async () => {
      let config = null;
      if (localStorage.getItem("token") !== null) {
        config = {
          headers: {
            "x-access-token": localStorage.getItem("token").toString(),
          },
        };
      }

      try {
        const response = await axios.put(
          `https://${apiUrl}/animations/time/` + animation_time,
          null,
          config
        );
      } catch (error) {}
    };
    await updateAnimationTimeData();
  };

  function handleDelete(idAnimation) {
    deleteAnimation(idAnimation);
  }

  async function handleEdit(idAnimation) {
    setEditAnimationId(idAnimation);
    await getAnimation(idAnimation);
    setEditAnimationMode(true);
  }

  function handleAdd() {
    setAddAnimationMode(true);
  }

  const AnimationsContent = Animations.map((v) => {
    return (
      <div className="animation-item" key={v.id}>
        <span className="animation-delete" onClick={() => handleDelete(v.id)}>
          Usuń
        </span>
        <span className="animation-edit" onClick={() => handleEdit(v.id)}>
          Edytuj
        </span>
        <span className="animation-title">
          <span className="grey">Link animacji:&ensp;</span> {v.animation_link}
        </span>
        <span className="animation-category">
          <span className="grey">Category:&ensp;</span> {v.category}
        </span>
      </div>
    );
  });

  return (
    <div className="overlay" style={styles}>
      {isLoader ? (
        <span className="loader"></span>
      ) : editAnimationMode ? (
        <>
          <form className="add-animation-form" onSubmit={handleSubmitEdit}>
            <span className="title">Edytuj animacje</span>
            <label htmlFor="title">Link animacji</label>
            <input
              type="text"
              id="title"
              value={animation_link}
              onChange={(event) => setAnimationLink(event.target.value)}
            />
            <label htmlFor="category">Kategoria</label>
            <select
              id="category"
              value={category}
              onChange={(event) => setCategory(event.target.value)}
            >
              <option value="main">Main</option>
              <option value="fashion">Fashion</option>
              <option value="automotive">Automotive</option>
              <option value="storytelling">Storytelling</option>
              <option value="music video">Music Video</option>
              <option value="documentary">Documentary</option>
            </select>
            <button type="submit">Aktualizuj</button>
          </form>
          <button onClick={back}>Wróć</button>
        </>
      ) : addAnimationMode ? (
        <>
          <form className="add-animation-form" onSubmit={handleSubmit}>
            <span className="title">Dodaj animacje</span>
            <label htmlFor="title">Link animacji</label>
            <input
              type="text"
              id="title"
              value={animation_link}
              onChange={(event) => setAnimationLink(event.target.value)}
            />
            <label htmlFor="category">Kategoria</label>
            <select
              id="category"
              value={category}
              onChange={(event) => setCategory(event.target.value)}
            >
              <option value="main">Main</option>
              <option value="fashion">Fashion</option>
              <option value="automotive">Automotive</option>
              <option value="storytelling">Storytelling</option>
              <option value="music video">Music Video</option>
              <option value="documentary">Documentary</option>
            </select>
            <button type="submit">Dodaj</button>
          </form>
          <button onClick={back}>Wróć</button>
        </>
      ) : (
        <>
          <div className="animation-container">
            {AnimationsContent}
            <div className="animation-add" onClick={() => handleAdd()}>
              Dodaj animację
            </div>
          </div>
          <span
            className="back-text"
            onClick={() => {
              localStorage.removeItem("token");
              setCounter((prev) => prev + 1);
            }}
          >
            Logout
          </span>

          <span
            className="back-text"
            onClick={() => {
              setView("videos");
            }}
          >
            Videos
          </span>
          <div className="animation-time-container">
            <span>Animation Time:</span>
            <input
              className="input-animation-time"
              type="number"
              id="title"
              value={animation_time}
              onChange={(event) => setAnimationTime(event.target.value)}
            />
            <button onClick={handleAnimationTimeUpdate}>Aktualizuj</button>
          </div>
        </>
      )}
    </div>
  );
}

export default Animations;
