import { useState, useRef } from "react";
import "./MenuLower.css";
import copyIcon from "../assets/copy.png";

function MenuLower({ data }) {
  const [contactText, setContactText] = useState("CONTACT");
  const [copyClasses, setCopyClasses] = useState("copy-text hidden");
  function getLinks() {
    function handleContactText() {
      if (contactText === "CONTACT") {
        setContactText("MICHAL.KORZEWSKI@PAPAYA-ROSTER.COM");
      } else {
        setContactText("CONTACT");
      }
    }

    function fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
      } catch (err) {}

      document.body.removeChild(textArea);
    }
    function copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(
        function () {
          //console.log('Async: Copying to clipboard was successful!');
        },
        function (err) {
          //console.error('Async: Could not copy text: ', err);
        }
      );
    }

    function handleCopyToClipboard() {
      copyTextToClipboard("MICHAL.KORZEWSKI@PAPAYA-ROSTER.COM");
      setCopyClasses("copy-text");
      window.setTimeout(() => {
        setCopyClasses("copy-text hidden");
      }, 2000);
    }
    if (data.text == "INFO") {
      return (
        <>
          <div className="menu-lower-link">
            <span onClick={handleContactText}>
              MICHAL.KORZEWSKI@PAPAYA-ROSTER.COM
            </span>{" "}
            <img
              className="copy-icon icon-info"
              src={copyIcon}
              onClick={handleCopyToClipboard}
            ></img>
            <span className={copyClasses}>Skopiowane do schowka</span>
          </div>
          <div className="menu-lower-credentials">
            <a href="https://www.instagram.com/mighty_roots/ " target="_blank">
              @mighty_roots
            </a>
          </div>
        </>
      );
    }

    return (
      <>
        {contactText == "MICHAL.KORZEWSKI@PAPAYA-ROSTER.COM" ? (
          <div className="menu-lower-link">
            <span onClick={handleContactText}>{contactText}</span>{" "}
            <img
              className="copy-icon icon-info"
              src={copyIcon}
              onClick={handleCopyToClipboard}
            ></img>
            <span className={copyClasses}>Skopiowane do schowka</span>
          </div>
        ) : (
          <div className="menu-lower-link" onClick={handleContactText}>
            {contactText}
          </div>
        )}
        {window.innerWidth < 500 &&
        contactText == "MICHAL.KORZEWSKI@PAPAYA-ROSTER.COM" ? null : (
          <div className="menu-lower-link">
            <a href="https://www.instagram.com/mighty_roots/ " target="_blank">
              FOLLOW
            </a>
          </div>
        )}
      </>
    );
  }

  const links = getLinks();

  return (
    <div className="lower-bar">
      <div className="menu-lower">{links}</div>
    </div>
  );
}

export default MenuLower;
