import overlayMedia from "../assets/bmw-1-low.webp";
import { useEffect, useState } from "react";
import axios from "axios";
import "./Videos.css";
import { apiUrl } from "../environment";

function Videos({ setCounter, setView }) {
  const [videos, setVideos] = useState([]);
  const [editVideoId, setEditVideoId] = useState(null);
  const [editVideoMode, setEditVideoMode] = useState(false);
  const [addVideoMode, setAddVideoMode] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("fashion");
  const [video_link, setVideoLink] = useState("");
  const [animation_link, setAnimationLink] = useState("");
  const [videoLinkError, setVideoLinkError] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const handleSubmitEdit = async (event) => {
    event.preventDefault();
    if (!validateLink(video_link)) {
      setVideoLinkError(true);
      return;
    }
    const videoData = {
      title,
      description,
      category,
      video_link,
      video_link,
    };
    const updateVideoData = async (videoData) => {
      let config = null;
      if (localStorage.getItem("token") !== null) {
        config = {
          headers: {
            "x-access-token": localStorage.getItem("token").toString(),
          },
        };
      }

      try {
        const response = await axios.put(
          `https://${apiUrl}/videos/` + editVideoId,
          videoData,
          config
        );
        setEditVideoMode(false);
        setEditVideoId(null);
      } catch (error) {}
    };
    await updateVideoData(videoData);
    setAnimationLink("");
    setTitle("");
    setCategory("fashion");
    setDescription("");
    setVideoLink("");
    setIsLoader(true);
    setTimeout(getVideos, 1000);
    setTimeout(() => {
      setIsLoader(false);
    }, 1001);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateLink(video_link)) {
      setVideoLinkError(true);
      return;
    }
    const videoData = {
      title,
      description,
      category,
      video_link,
      animation_link,
    };
    const postVideoData = async (videoData) => {
      let config = null;
      if (localStorage.getItem("token") !== null) {
        config = {
          headers: {
            "x-access-token": localStorage.getItem("token").toString(),
          },
        };
      }

      try {
        const response = await axios.post(
          `https://${apiUrl}/videos`,
          videoData,
          config
        );

        setAddVideoMode(false);
      } catch (error) {}
    };
    await postVideoData(videoData);
    setAnimationLink("");
    setTitle("");
    setCategory("fashion");
    setDescription("");
    setVideoLink("");
    setIsLoader(true);
    setTimeout(getVideos, 1000);
    setTimeout(() => {
      setIsLoader(false);
    }, 1001);
  };

  function back() {
    setAnimationLink("");
    setTitle("");
    setCategory("fashion");
    setDescription("");
    setVideoLink("");
    setAddVideoMode(false);
    setEditVideoMode(false);
    setEditVideoId(null);
  }

  const validateLink = (link) => {
    // simple URL validation, assuming http(s) protocol
    const regex = /^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/;
    return regex.test(link);
  };

  const styles = {
    backgroundImage: "url(" + overlayMedia + ")",
    backgroundSize: "cover",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const getVideos = async () => {
    try {
      let config = null;
      if (localStorage.getItem("token") != null) {
        config = {
          headers: {
            "x-access-token": localStorage.getItem("token").toString(),
          },
        };
      }
      const response = await axios.get(`https://${apiUrl}/videos`, config);
      setVideos(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getVideo = async (idVideo) => {
    try {
      let config = null;
      if (localStorage.getItem("token") != null) {
        config = {
          headers: {
            "x-access-token": localStorage.getItem("token").toString(),
          },
        };
      }
      const response = await axios.get(
        `https://${apiUrl}/videos/` + idVideo,
        config
      );
      setTitle(response.data.title);
      setVideoLink(response.data.video_link);
      setAnimationLink(response.data.animation_link);
      setDescription(response.data.description);
      setCategory(response.data.category);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteVideo = async (idVideo) => {
    try {
      let config = null;
      if (localStorage.getItem("token") != null) {
        config = {
          headers: {
            "x-access-token": localStorage.getItem("token").toString(),
          },
        };
      }
      const response = await axios.delete(
        `https://${apiUrl}/videos/` + idVideo,
        config
      );

      setVideos((current) => current.filter((video) => video.id !== idVideo));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getVideos();
  }, [addVideoMode]);

  function handleDelete(idVideo) {
    deleteVideo(idVideo);
  }

  async function handleEdit(idVideo) {
    setEditVideoId(idVideo);
    await getVideo(idVideo);
    setEditVideoMode(true);
  }

  function handleAdd() {
    setAddVideoMode(true);
  }

  const videosContent = videos.map((v) => {
    return (
      <div className="video-item" key={v.id}>
        <span className="video-delete" onClick={() => handleDelete(v.id)}>
          Usuń
        </span>
        <span className="video-edit" onClick={() => handleEdit(v.id)}>
          Edytuj
        </span>
        <span className="video-title">
          <span className="grey">Tytuł:&ensp;</span> {v.title}
        </span>
        <span className="video-category">
          <span className="grey">Category:&ensp;</span> {v.category}
        </span>
      </div>
    );
  });

  return (
    <div className="overlay" style={styles}>
      {isLoader ? (
        <span className="loader"></span>
      ) : editVideoMode ? (
        <>
          <form className="add-video-form" onSubmit={handleSubmitEdit}>
            <span className="title">Edytuj wideo</span>
            <label htmlFor="title">Tytuł</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
            />

            <label htmlFor="category">Kategoria</label>
            <select
              id="category"
              value={category}
              onChange={(event) => setCategory(event.target.value)}
            >
              <option value="fashion">Fashion</option>
              <option value="automotive">Automotive</option>
              <option value="storytelling">Storytelling</option>
              <option value="music video">Music Video</option>
              <option value="documentary">Documentary</option>
            </select>

            <label htmlFor="videoLink">Video Link</label>
            <input
              type="text"
              id="videoLink"
              value={video_link}
              onChange={(event) => setVideoLink(event.target.value)}
            />
            {videoLinkError && (
              <span style={{ color: "red" }}>Please enter a valid URL</span>
            )}

            <button type="submit">Aktualizuj</button>
          </form>
          <button onClick={back}>Wróć</button>
        </>
      ) : addVideoMode ? (
        <>
          <form className="add-video-form" onSubmit={handleSubmit}>
            <span className="title">Dodaj wideo</span>
            <label htmlFor="title">Tytuł</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
            />

            <label htmlFor="category">Kategoria</label>
            <select
              id="category"
              value={category}
              onChange={(event) => setCategory(event.target.value)}
            >
              <option value="fashion">Fashion</option>
              <option value="automotive">Automotive</option>
              <option value="storytelling">Storytelling</option>
              <option value="music video">Music Video</option>
              <option value="documentary">Documentary</option>
            </select>

            <label htmlFor="videoLink">Video Link</label>
            <input
              type="text"
              id="videoLink"
              value={video_link}
              onChange={(event) => setVideoLink(event.target.value)}
            />
            {videoLinkError && (
              <span style={{ color: "red" }}>Wprowadź poprawny adres URL</span>
            )}

            <button type="submit">Dodaj</button>
          </form>
          <button onClick={back}>Wróć</button>
        </>
      ) : (
        <>
          <div className="video-container">
            {videosContent}
            <div className="video-add" onClick={() => handleAdd()}>
              Dodaj video
            </div>
          </div>
          <span
            className="back-text"
            onClick={() => {
              localStorage.removeItem("token");
              setCounter((prev) => prev + 1);
            }}
          >
            Logout
          </span>

          <span
            className="back-text"
            onClick={() => {
              setView("animations");
            }}
          >
            Animations
          </span>
        </>
      )}
    </div>
  );
}

export default Videos;
